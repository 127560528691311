
export default class InputUnit {
    static totalSeq: number = 0;
    public seq: string;
    constructor(
        public kaiRyou: string = '',
        public kaiYen: string = '',
        public uriRyou: string = '',
        public uriYen: string = '',
        public memo: string = '',
    ) {
        this.seq = (InputUnit.totalSeq++).toString();
    }
}


