import Finance from "../Finance";
import BigNumber from "bignumber.js";

export default class Recipe {
    name: string;
    kaiRyou: BigNumber; uriRyou: BigNumber;
    kaiYen: BigNumber; uriYen: BigNumber;
    afterFin: Finance;


    constructor(
        name: string,
        kaiRyou: BigNumber, uriRyou: BigNumber,
        afterFin: Finance) {
        this.name = name;
        this.kaiRyou = kaiRyou; this.uriRyou = uriRyou;
        this.afterFin = afterFin;

        // this.kaiYen = kaiRyou * afterFin.nowTanka;
        this.kaiYen = kaiRyou.multipliedBy(afterFin.nowTanka);

        // this.uriYen = uriRyou * afterFin.nowTanka;
        this.uriYen = uriRyou.multipliedBy(afterFin.nowTanka);

    }
}