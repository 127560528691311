import Finance from "./model/Finance";
import "./FinanceView.css";
import { ryouToTag, yenToTag } from "./Util";


export default function FinanceView({ finance }: { finance: Finance }) {
    const fin = finance;
    return <div className="finance">

        <div className="title">損益関係</div>
        <div className="list">
            <div>
                <div className="label">損益</div>
                <div className="value">{yenToTag(fin.mouke)}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">譲渡価格</div>
                <div className="value">{yenToTag(fin.uriYen, false)}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">譲渡原価</div>
                <div className="value">{yenToTag(fin.jyoutoCost, false)}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">購入単価</div>
                <div className="value">{yenToTag(fin.kaiTanka, false)}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">売却単価</div>
                <div className="value">{yenToTag(fin.uriTanka, false)}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">売却数量</div>
                <div className="value">{ryouToTag(fin.uriRyou, false)}</div>
            </div>
        </div>
        
        <div className="title">残存関係</div>
        <div className="list">
            <div>
                <div className="label">残存含み損益</div>
                <div className="value">{yenToTag(fin.zanFukumiMouke)}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">残存評価額</div>
                <div className="value">{yenToTag(fin.zanValueYen, false)}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">残存原価</div>
                <div className="value">{yenToTag(fin.zanCost, false)}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">残存数量</div>
                <div className="value">{ryouToTag(fin.zanRyou, false)}</div>
            </div>
        </div>
    </div>;
}
