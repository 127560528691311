import AvenueIF from "./AvenueIF";
import { convertArg } from "./AvenueUtil";
import Finance from "../Finance";
import Recipe from "./Recipe";
import BigNumber from "bignumber.js";

export default class RyouijiAvenue implements AvenueIF {
    name = `数量維持`;

    public getMoukeLimit(finance: Finance) {
        const { kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka } = finance;

        const { a, b, j, k, n } = convertArg(BigNumber(0), kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka);
        // const m = j * (k - n) + a * (-b + n); = j * (k - n) + a * (n - b)
        const m = j.multipliedBy(k.minus(n)).plus(a.multipliedBy(n.minus(b)));
        const minusPattenRyou = this.getRecipe(m.minus(1), finance);
        if (minusPattenRyou !== null && BigNumber(0).isLessThan(minusPattenRyou.kaiRyou)) {
            return m.minus(1);
        }
        const plusPattenRyou = this.getRecipe(m.plus(1), finance);
        if (plusPattenRyou !== null && BigNumber(0).isLessThan(plusPattenRyou.kaiRyou)) {
            return m.plus(1);
        }

        return null;
    }

    getRecipe(mouke: BigNumber, finance: Finance) {
        const { kaiRyou, kaiYen, kaiTanka, uriRyou, uriYen, uriTanka, nowTanka } = finance;

        const { m, a, b, j, k, n } = convertArg(mouke, kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka);

        //if (a * (b - n) + j * (n - k) + m === 0) return null;
        if (a.multipliedBy(b.minus(n)).plus(j.multipliedBy(n.minus(k))).plus(m).isEqualTo(0)) return null;
        //const u = -(a * (b * j - j * k + m)) / (a * (b - n) + j * (n - k) + m);
        const u = BigNumber(-1).multipliedBy(a).multipliedBy(b.multipliedBy(j).minus(j.multipliedBy(k)).plus(m)).dividedBy(a.multipliedBy(b.minus(n)).plus(j.multipliedBy(n.minus(k))).plus(m));
        const z = u;
        const addKaiRyou = u;
        const addUriRyou = z;

        if (addKaiRyou.isLessThanOrEqualTo(0) || addKaiRyou.isEqualTo(Infinity)) {
            return null;
        }

        const afterFin = Finance.create({ kaiRyou, kaiYen, uriRyou, uriYen, nowTanka, addKaiRyou, addUriRyou });
        return new Recipe(this.name, addKaiRyou, addUriRyou, afterFin);
    }

}
