import { ChangeEvent, memo, useEffect, useState } from "react";
import Finance from "./model/Finance";
import FinanceView from "./FinanceView";
import AvenueIF from "./model/avenue/AvenueIF";
import UrinomiAvenue from "./model/avenue/UrinomiAvenue";
import KainomiAvenue from "./model/avenue/KainomiAvenue";
import RyouijiAvenue from "./model/avenue/RyouijiAvenue";
import BigNumber from "bignumber.js";
import "./Simulator.css";
import DiffFinanceView from "./DiffFinanceView";
import { ryouToTag, yenToTag } from "./Util";

export default memo(function Simulator(
    { finance }: { finance: Finance | null }) {

    const [mouke, setMouke] = useState<number>(0);

    useEffect(() => {
        console.debug('Simulator useEffect [finance]');
        if (finance) {
            const newMouke = loadMouke();
            if (newMouke !== null && minMouke <= newMouke && newMouke <= maxMouke) {
                setMouke(newMouke);
            } else {
                setMouke(finance.mouke.toNumber());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finance]);

    if (!finance) {
        return null;
    }

    const avenueArray: AvenueIF[] = [new RyouijiAvenue(), new UrinomiAvenue(), new KainomiAvenue()];
    const moukeLimitObjArray = avenueArray.map(s => ({ avenueName: s.name, moukeLimit: s.getMoukeLimit(finance) }));
    const moukeLimitArray = moukeLimitObjArray.map(obj => obj.moukeLimit?.toNumber()).filter((limit): limit is NonNullable<typeof limit> => limit !== null && limit !== undefined);
    const minMouke = Math.min(...moukeLimitArray, finance.mouke.toNumber());
    const maxMouke = Math.max(...moukeLimitArray, finance.mouke.toNumber());

    const recipeArray = avenueArray.map(s => s.getRecipe(BigNumber(mouke), finance)).filter((r): r is NonNullable<typeof r> => r !== null);

    console.debug('Rend Simulator.');
    return <div className="simulator">
        <h2>現状</h2>
        <FinanceView finance={finance} />

        <h2>Step3 望みの損益を選ぶ</h2>

        <div className="slider" style={{ display: 'flex', justifyContent: 'center' }}>
            <input type="range"
                value={mouke}
                min={minMouke} max={maxMouke}
                step="any"
                style={{ width: '95%', cursor: 'pointer' }}
                list="markerList"
                onChange={onChangeMoukeSlider}
            />
            <datalist id="markerList">
                <option value="0"></option>{/*0がスライダーの範囲外の場合もあるが無視されるので良し。*/}
                <option value={finance.mouke.toNumber()}></option>
                <option value={minMouke}></option>
                <option value={maxMouke}></option>
            </datalist>
        </div>
        <div className="sliderLabel" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
                {minMouke.toLocaleString()}<span className="unit">円</span>
            </span>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <label>望みの損益</label>
                <input type="text" value={mouke.toLocaleString()} onChange={onChangeMoukeText} /><span className="unit">円</span>
            </div>
            <span>
                {maxMouke.toLocaleString()}<span className="unit">円</span>
            </span>
        </div>

        <div style={{ minHeight: '100vh', margin: '2rem 0' }}>
            {recipeArray.map((recipe, index) => <div key={index} className={'offer'}>
                <div className="policy">提案{2 <= recipeArray.length ? (index + 1) : ''} {recipe.name} 方式</div>
                <div>
                    <div className="operation">
                        {recipe.kaiRyou.isZero() || <span>買え!</span>}
                        {(recipe.kaiRyou.isGreaterThan(0) && recipe.uriRyou.isGreaterThan(0)) && <span className="and">そして</span>}
                        {recipe.uriRyou.isZero() || <span>売れ!</span>}
                    </div>

                    {recipe.kaiRyou.isZero() || <div className="operationKae">
                        <div>
                            <div className="label">購入数量</div>
                            <div className="value">{ryouToTag(recipe.kaiRyou, false)}</div>
                        </div>
                        <div>
                            <div className="label">購入額</div>
                            <div className="value">{yenToTag(recipe.kaiYen, false)}<span className="unit">円</span></div>
                        </div>
                    </div>}

                    {recipe.uriRyou.isZero() || <div className="operationUre">
                        <div>
                            <div className="label">売却数量</div>
                            <div className="value">{ryouToTag(recipe.uriRyou, false)}</div>
                        </div>
                        <div>
                            <div className="label">売却額</div>
                            <div className="value">{yenToTag(recipe.uriYen, false)}<span className="unit">円</span></div>
                        </div>
                    </div>}
                </div>
                <details className={'detail'}>
                    <summary>やればこうなる</summary>
                    <DiffFinanceView befFin={finance} aftFin={recipe.afterFin} />
                </details>
            </div>)}
            {recipeArray.length === 0 ? <div>今の状態です</div> : null}
        </div>
    </div>;

    function onChangeMoukeSlider(event: ChangeEvent<HTMLInputElement>) {
        let newMouke = Number(event.target.value);
        if (newMouke !== minMouke && newMouke !== maxMouke) {
            newMouke = Math.round(newMouke);
        }
        setMouke(newMouke);
        saveMouke(newMouke);
    }

    function onChangeMoukeText(event: ChangeEvent<HTMLInputElement>) {
        const moukeStr = event.target.value.replaceAll(',', '').trim();
        let newMouke = Number(moukeStr);
        if (newMouke === null || Number.isNaN(newMouke)) {
            return;
        }
        if (newMouke < minMouke || maxMouke < newMouke) {
            return;
        }
        setMouke(newMouke);
        saveMouke(newMouke);
    }

    function saveMouke(newMouke: number) {
        const saveObj: any = {};
        saveObj.mouke = newMouke;
        const json = JSON.stringify(saveObj);
        localStorage.setItem('moukeJson', json);
    }

    function loadMouke(): number | null {
        const json = localStorage.getItem('moukeJson');
        if (!json) {
            return null;
        }

        const saveObj = JSON.parse(json);
        if (!saveObj) {
            return null;
        }

        if (saveObj.mouke === null || saveObj.mouke === undefined) {
            return null;
        }

        return saveObj.mouke;
    }

})

