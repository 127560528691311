import BigNumber from "bignumber.js";

export default class Finance {
    zanRyou: BigNumber;

    kaiRyou: BigNumber;
    uriRyou: BigNumber;

    kaiYen: BigNumber;
    uriYen: BigNumber;

    // 損益関係
    mouke: BigNumber;
    jyoutoCost: BigNumber;

    // 残量関係
    zanFukumiMouke: BigNumber;
    zanCost: BigNumber;
    zanValueYen: BigNumber;

    // 単価関係
    kaiTanka: BigNumber;
    uriTanka: BigNumber;
    nowTanka: BigNumber;

    constructor(
        kaiRyou: BigNumber,
        kaiYen: BigNumber,
        uriRyou: BigNumber,
        uriYen: BigNumber,
        nowTanka: BigNumber,
    ) {
        // this.zanRyou = kaiRyou - uriRyou;
        this.zanRyou = kaiRyou.minus(uriRyou);

        this.kaiRyou = kaiRyou;
        this.uriRyou = uriRyou;
        this.kaiYen = kaiYen;
        this.uriYen = uriYen;

        if (uriRyou.isZero()) {
            this.mouke = BigNumber(0);
        } else {
            // this.mouke = (uriYen / uriRyou - kaiYen / kaiRyou) * uriRyou;
            this.mouke = uriYen.dividedBy(uriRyou).minus(kaiYen.dividedBy(kaiRyou)).multipliedBy(uriRyou);
        }

        // this.zanFukumiMouke = (nowTanka - kaiYen / kaiRyou) * this.zanRyou;
        this.zanFukumiMouke = nowTanka.minus(kaiYen.dividedBy(kaiRyou)).multipliedBy(this.zanRyou);

        // this.zanCost = kaiYen / kaiRyou * this.zanRyou;
        this.zanCost = kaiYen.dividedBy(kaiRyou).multipliedBy(this.zanRyou);

        // this.jyoutoCost = kaiYen / kaiRyou * uriRyou;
        this.jyoutoCost = kaiYen.dividedBy(kaiRyou).multipliedBy(uriRyou);

        // this.zanValueYen = nowTanka * this.zanRyou;
        this.zanValueYen = nowTanka.multipliedBy(this.zanRyou);

        this.nowTanka = nowTanka;

        if (kaiRyou.isZero()) {
            this.kaiTanka = BigNumber(0);
        } else {
            // this.kaiTanka = kaiYen / kaiRyou;
            this.kaiTanka = kaiYen.dividedBy(kaiRyou);
        }
        if (uriRyou.isZero()) {
            this.uriTanka = BigNumber(0);
        } else {
            // this.uriTanka = uriYen / uriRyou;
            this.uriTanka = uriYen.dividedBy(uriRyou);
        }
    }


    static create({ kaiRyou, kaiYen, uriRyou, uriYen, nowTanka, addKaiRyou, addUriRyou }
        : { kaiRyou: BigNumber, kaiYen: BigNumber, uriRyou: BigNumber, uriYen: BigNumber, nowTanka: BigNumber, addKaiRyou: BigNumber, addUriRyou: BigNumber }) {
        return new Finance(
            // kaiRyou + addKaiRyou,
            // kaiYen + addKaiRyou * nowTanka,
            // uriRyou + addUriRyou,
            // uriYen + addUriRyou * nowTanka,
            // nowTanka
            kaiRyou.plus(addKaiRyou),
            kaiYen.plus(addKaiRyou.multipliedBy(nowTanka)),
            uriRyou.plus(addUriRyou),
            uriYen.plus(addUriRyou.multipliedBy(nowTanka)),
            nowTanka
        );
    }

}