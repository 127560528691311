import BigNumber from "bignumber.js";

export function yenToTag(bNum: BigNumber, isSign = true, mustDecimalPlace = 0) {
    //　四捨五入
    bNum = bNum.dp(mustDecimalPlace, BigNumber.ROUND_HALF_UP);

    const sign = isSign && bNum.isGreaterThan(0) ? '+' : '';
    const [int, dec] = divideBigNumberMax(bNum, mustDecimalPlace);
    if (1 <= dec.length) {
        return <span>{`${sign}${int}.${dec}`}</span>;
    } else {
        return <span>{`${sign}${int}`}</span>;
    }
}

export function ryouToTag(bNum: BigNumber, isSign = true, maxDecimalPlace = 6) {
    const sign = isSign && bNum.isGreaterThan(0) ? '+' : '';
    const [int, dec] = divideBigNumberMax(bNum, maxDecimalPlace);
    if (1 <= dec.length) {
        return <span>{`${sign}${int}.${dec}`}</span>;
    } else {
        return <span>{`${sign}${int}`}</span>;
    }

}

export function yenToDiffTag(bNum: BigNumber, mustDecimalPlace = 0) {
    //　四捨五入
    bNum = bNum.dp(mustDecimalPlace, BigNumber.ROUND_HALF_UP);

    let sign;
    let className;
    if (bNum.isZero()) {
        sign = '→';
        className = 'zero';
    } else {
        sign = bNum.isGreaterThan(0) ? '↗' : '↘';
        className = bNum.isGreaterThan(0) ? 'positive' : 'negative';
    }
    bNum = bNum.abs();
    const [int, dec] = divideBigNumberMust(bNum, mustDecimalPlace);

    if (1 <= dec.length) {
        return <span className={className}>{`${sign}${int}.${dec}`}</span>;
    } else {
        return <span className={className}>{`${sign}${int}`}</span>;
    }
}

export function ryouToDiffTag(bNum: BigNumber, maxDecimalPlace = 6) {
    let sign;
    let className;
    if (bNum.isZero()) {
        sign = '→';
        className = 'zero';
    } else {
        sign = bNum.isGreaterThan(0) ? '↗' : '↘';
        className = bNum.isGreaterThan(0) ? 'positive' : 'negative';
    }
    bNum = bNum.abs();
    const [int, dec] = divideBigNumberMax(bNum, maxDecimalPlace);

    if (1 <= dec.length) {
        return <span className={className}>{`${sign}${int}.${dec}`}</span>;
    } else {
        return <span className={className}>{`${sign}${int}`}</span>;
    }
}

export function divideBigNumberMust(bNum: BigNumber, mustDecPlace: number): [string, string] {

    const original = bNum.toFormat();
    const dotIndex = original.indexOf('.');

    let int: string;
    let dec: string;
    if (dotIndex === -1) {
        int = original;
        dec = '';
    } else {
        int = original.substring(0, dotIndex);
        dec = original.substring(dotIndex + 1);
        dec = dec.substring(0, mustDecPlace);
    }
    if (dec.length < mustDecPlace) {
        dec += '0'.repeat(mustDecPlace - dec.length);
    }

    return [int, dec];
}

export function divideBigNumberMax(bNum: BigNumber, maxDecPlace: number): [string, string] {

    const original = bNum.toFormat();
    const dotIndex = original.indexOf('.');

    let int: string;
    let dec: string;
    if (dotIndex === -1) {
        int = original;
        dec = '';
    } else {
        int = original.substring(0, dotIndex);
        dec = original.substring(dotIndex + 1);
        dec = dec.substring(0, maxDecPlace);
    }

    return [int, dec];
}
