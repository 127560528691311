import AvenueIF from "./AvenueIF";
import { convertArg } from "./AvenueUtil";
import Finance from "../Finance";
import Recipe from "./Recipe";
import BigNumber from "bignumber.js";

export default class UrinomiAvenue implements AvenueIF {
    name = `売却のみ`;

    public getMoukeLimit(finance: Finance) {
        const { kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka } = finance;
        const { a, b, j, k, n } = convertArg(BigNumber(-1), kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka);

        // const m = -a * b + a * n + j * k - j * n;
        const m = BigNumber(-1).multipliedBy(a).multipliedBy(b).plus(a.multipliedBy(n)).plus(j.multipliedBy(k)).minus(j.multipliedBy(n));
        // const nowMouke = uriRyou * (uriTanka - kaiTanka);
        const nowMouke = uriRyou.multipliedBy(uriTanka.minus(kaiTanka));

        if (m.isEqualTo(nowMouke) || m.isNaN()) {
            return null;
        } else {
            return m;
        }
    }


    getRecipe(mouke: BigNumber, finance: Finance) {
        const { kaiRyou, kaiYen, kaiTanka, uriRyou, uriYen, uriTanka, nowTanka } = finance;
        const { m, b, j, k, n } = convertArg(mouke, kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka);
        const u = BigNumber(0);

        if (b.minus(n).isZero()) return null;

        // const z = -(b * j - j * k + m) / (b - n); = (b * j - j * k + m) / (n - b)
        const z = b.multipliedBy(j).minus(j.multipliedBy(k)).plus(m).dividedBy(n.minus(b));

        const addKaiRyou = u;
        const addUriRyou = z;
        // if (kaiRyou - uriRyou < addUriRyou || addUriRyou <= 0) {
        if (kaiRyou.minus(uriRyou).isLessThan(addUriRyou) || addUriRyou.isLessThanOrEqualTo(0)) {
            return null;
        }

        const afterFin = Finance.create({ kaiRyou, kaiYen, uriRyou, uriYen, nowTanka, addKaiRyou, addUriRyou });
        return new Recipe(this.name, addKaiRyou, addUriRyou, afterFin);
    }
}
