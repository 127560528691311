import { useState } from "react";
import InputForm from "./InputForm";
import Simulator from "./Simulator";
import Finance from "./model/Finance";

export default function Souheikin() {
    const [finance, setFinance] = useState<Finance | null>(null);

    console.debug('Rend Tedama.');
    return <div>
        <InputForm setFinance={setFinance} />
        <Simulator finance={finance} />
        <div style={{ marginTop: '50vh' }}>
            <p>
                注意事項<br />
                次を前提にしているため、実際はこの通りにはなりません。<br />
                ・売買手数料なし<br />
                ・スプレッドなし<br />
            </p>
        </div>
    </div>;
}