import React from 'react';
import './App.css';
import BigNumber from 'bignumber.js';


function divideMust(bNum: BigNumber, mustDecPlace = 1): [string, string] {
  const original = bNum.toFormat();
  const dotIndex = original.indexOf('.');

  let int: string;
  let dec: string;
  if (dotIndex === -1) {
    int = original;
    dec = '';
  } else {
    int = original.substring(0, dotIndex);
    dec = original.substring(dotIndex + 1);
    dec = dec.substring(0, mustDecPlace);
  }
  if (dec.length < mustDecPlace) {
    dec += '0'.repeat(mustDecPlace - dec.length);
  }

  return [int, dec];
}

function divideMax(bNum: BigNumber, maxDecPlace = 3): [string, string] {
  const original = bNum.toFormat();
  const dotIndex = original.indexOf('.');

  let int: string;
  let dec: string;
  if (dotIndex === -1) {
    int = original;
    dec = '';
  } else {
    int = original.substring(0, dotIndex);
    dec = original.substring(dotIndex + 1);
    dec = dec.substring(0, maxDecPlace);
  }

  return [int, dec];
}

function Zpp() {

  const b = BigNumber(20000).dividedBy(3).plus(1);
  //const b = BigNumber(-12.3456);
  //const b = BigNumber(-777);
  [
    BigNumber(20000).dividedBy(3).plus(1),
    BigNumber(-12.3),
    BigNumber(123)
  ].forEach(bNum => {
    const str = divideMust(bNum);
    console.log(str, bNum.toFormat());
  });

  [
    BigNumber(20000).dividedBy(3).plus(1),
    BigNumber(-12.3),
    BigNumber(123)
  ].forEach(bNum => {
    const str = divideMax(bNum);
    console.log('max', str, bNum.toFormat());
  });



  return (
    <div>
      <div>
        {b.toFormat()}
      </div>
      <div>
        {b.toFormat({
          prefix: 'yen',
          decimalSeparator: '.',
          groupSeparator: ',',
          groupSize: 3,
          secondaryGroupSize: 0,
          fractionGroupSeparator: ' ',
          fractionGroupSize: 0,
          suffix: '円'
        })}
      </div>
    </div>
  );
}

export default Zpp;
