import BigNumber from "bignumber.js";

export function convertArg(
    mouke: BigNumber,
    kaiRyou: BigNumber,
    kaiTanka: BigNumber,
    uriRyou: BigNumber,
    uriTanka: BigNumber,
    nowTanka: BigNumber,
) {
    return {
        m: mouke,
        a: kaiRyou,
        b: kaiTanka,
        j: uriRyou,
        k: uriTanka,
        n: nowTanka
    };
}

