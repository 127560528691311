import { memo, useEffect, useState } from "react";
import Finance from "./model/Finance";
import InputUnit from "./model/InputUnit";
import BigNumber from "bignumber.js";
import './InputForm.css';
import { yenToTag } from "./Util";

export default memo(function InputForm(
    { setFinance }: { setFinance: (f: Finance | null) => void }) {

    const [unitArray, setUnitArray] = useState<Array<InputUnit>>([]);
    const [nowTanka, setNowTanka] = useState<string>('');

    useEffect(() => {
        console.debug('InputForm useEffect[unitArray, nowTanka].');
        setFinance(finance);
        save();

        if (unitArray.length === 0) {
            const unit1 = new InputUnit();
            const unit2 = new InputUnit();
            setUnitArray([unit1, unit2]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitArray, nowTanka]);

    useEffect(() => {
        console.debug('InputForm useEffect[].');
        load();
    }, []);

    let kaiRyouSum = 0; let kaiYenSum = 0;
    let uriRyouSum = 0; let uriYenSum = 0;
    const unitObjArray = unitArray.map(unit => {
        const kaiRyou = toNumber(unit.kaiRyou);
        const kaiYen = toNumber(unit.kaiYen);
        const uriRyou = toNumber(unit.uriRyou);
        const uriYen = toNumber(unit.uriYen);

        let kaiTanka; let kaiClass;
        if (isValueless(kaiRyou) || isValueless(kaiYen)) {
            kaiTanka = null;
            kaiClass = 'valueless';
        } else {
            kaiTanka = kaiYen! / kaiRyou!;
            kaiClass = '';
            kaiRyouSum += kaiRyou!; kaiYenSum += kaiYen!;
        }
        let uriTanka; let uriClass;
        if (isValueless(uriRyou) || isValueless(uriYen)) {
            uriTanka = null;
            uriClass = 'valueless';
        } else {
            uriTanka = uriYen! / uriRyou!;
            uriClass = '';
            uriRyouSum += uriRyou!; uriYenSum += uriYen!;
        }

        const kaiRyouClass = isWrong(kaiRyou) ? 'wrong' : '';
        const kaiYenClass = isWrong(kaiYen) ? 'wrong' : '';
        const uriRyouClass = isWrong(uriRyou) ? 'wrong' : '';
        const uriYenClass = isWrong(uriYen) ? 'wrong' : '';

        return {
            kaiTanka, uriTanka,
            kaiClass, uriClass,
            kaiRyouClass, kaiYenClass, uriRyouClass, uriYenClass,
            unit,
        };
    });
    const nowTankaNum = toNumber(nowTanka);
    const nowTankaClass = isWrong(nowTankaNum) ? 'wrong' : '';

    let finance: Finance | null = null;
    if (0 < kaiRyouSum && 0 < kaiYenSum && nowTankaNum && 0 < nowTankaNum && uriRyouSum <= kaiRyouSum) {
        finance = new Finance(
            BigNumber(kaiRyouSum),
            BigNumber(kaiYenSum),
            BigNumber(uriRyouSum),
            BigNumber(uriYenSum),
            BigNumber(nowTankaNum));
    } else {
        finance = null;
    }

    console.debug('Rend InputForm.');
    return <div className="inputForm">
        <h2>Step1 売買情報を入力</h2>
        {unitObjArray.map((u, index) => (
            <div key={u.unit.seq} className="inputRow" >
                <div className="kai">
                    <div className="ryou">
                        <label htmlFor={index + '.kaiRyou'}>購入数量</label>
                        <div>
                            <input
                                id={index + '.kaiRyou'}
                                className={u.kaiClass + ' ' + u.kaiRyouClass}
                                value={u.unit.kaiRyou}
                                onChange={event => changeUnit(index, 'kaiRyou', event.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="yen">
                        <label htmlFor={index + '.kaiYen'}>購入額</label>
                        <div>
                            <input
                                id={index + '.kaiYen'}
                                className={u.kaiClass + ' ' + u.kaiYenClass}
                                value={u.unit.kaiYen}
                                onChange={event => changeUnit(index, 'kaiYen', event.target.value)}
                                autoComplete="off"
                            />
                            <span className='unit'>円</span>
                        </div>
                    </div>
                    <div className="tanka" style={{ visibility: u.kaiTanka === null ? "hidden" : "visible" }}>
                        <label>単価</label>
                        <div className={u.kaiClass}>
                            {u.kaiTanka === null ? null : <>{yenToTag(BigNumber(u.kaiTanka), false)}</>}<span className="unit">円</span>
                        </div>
                    </div>
                </div>

                <div className="uri">
                    <div className="ryou">
                        <label htmlFor={index + '.uriRyou'}>売却数量</label>
                        <div>
                            <input
                                id={index + '.uriRyou'}
                                className={u.uriClass + ' ' + u.uriRyouClass}
                                value={u.unit.uriRyou}
                                onChange={event => changeUnit(index, 'uriRyou', event.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="yen">
                        <label htmlFor={index + '.uriYen'}>売却額</label>
                        <div>
                            <input
                                id={index + '.uriYen'}
                                className={u.uriClass + ' ' + u.uriYenClass}
                                value={u.unit.uriYen}
                                onChange={event => changeUnit(index, 'uriYen', event.target.value)}
                                autoComplete="off"
                            />
                            <span className='unit'>円</span>
                        </div>
                    </div>
                    <div className="tanka" style={{ visibility: u.uriTanka === null ? "hidden" : "visible" }}>
                        <label>単価</label>
                        <div className={u.uriClass}>
                            {u.uriTanka === null ? null : <>{yenToTag(BigNumber(u.uriTanka), false, 0)}<span className='unit'>円</span></>}
                        </div>
                    </div>
                </div>

                <div className="etc">
                    <div className="memo">
                        <label htmlFor={index + '.memo'}>メモ</label>
                        <div>
                            <input
                                id={index + '.memo'}
                                className="memo"
                                value={u.unit.memo}
                                onChange={event => changeUnit(index, 'memo', event.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="operation">
                        <div>
                            <button onClick={() => setUnitArray((uArr) => { uArr.splice(index, 1); return [...uArr]; })}>－</button>
                        </div>
                        <div>
                            <button onClick={() => setUnitArray((uArr) => { uArr.splice(index + 1, 0, new InputUnit()); return [...uArr]; })}>＋</button>
                        </div>
                    </div>
                </div>
            </div>
        ))}



        <h2>Step2 市場価格を入力</h2>
        <div className="inputNow">
            <label htmlFor="inputNow">市場での現在単価</label>
            <div>
                <input
                    id="inputNow"
                    className={nowTankaClass}
                    value={nowTanka}
                    onChange={event => setNowTanka(event.target.value)}
                    autoComplete="off"
                /><span className='unit'>円</span>
            </div>
        </div>
    </div >;

    function changeUnit(index: number, name: keyof InputUnit, value: string): void {
        setUnitArray(pre => {
            pre[index][name]! = value;
            return [...pre];
        });
    }

    function save() {
        if (unitArray.length === 0 && nowTanka === '') {
            return;
        }

        const saveObj: any = {};
        saveObj.unitArray = unitArray;
        saveObj.nowTanka = nowTanka;
        const json = JSON.stringify(saveObj);
        localStorage.setItem('inputJson', json);
    }

    function load() {
        const json = localStorage.getItem('inputJson');
        if (!json) {
            return;
        }

        const saveObj = JSON.parse(json);
        if (!saveObj) {
            return;
        }

        saveObj.unitArray.forEach((unit: InputUnit, index: number) => {
            unit.seq = index.toString();
        });
        InputUnit.totalSeq = saveObj.unitArray.length + 10;
        setUnitArray(saveObj.unitArray);
        setNowTanka(saveObj.nowTanka);
    }
})


// return null : 空文字,0
// return undefined : 数値にできない, 負の値
function toNumber(str: string): number | null | undefined {
    str = str.replaceAll(',', '');
    str = str.replaceAll('，', '');
    str = str.replaceAll('．', '.');
    str = str.replace(/[０-９]/g, m => '０１２３４５６７８９'.indexOf(m).toString());
    str = str.trim();

    if (str === '') {
        return null;
    }
    if (!str.match(/^[\d.]+$/g)) {
        return undefined;
    }

    const num = Number(str);
    if (Number.isNaN(num)) {
        return undefined;
    }
    if (0 < num) {
        return num;
    } else {
        return null;
    }
}

function isValueless(num: number | null | undefined): boolean {
    if (num === undefined) {
        return true;
    } else if (num === null) {
        return true;
    } else {
        return false;
    }
}

function isWrong(num: number | null | undefined): boolean {
    if (num === undefined) {
        return true;
    } else if (num === null) {
        return false;
    } else {
        return false;
    }
}

