import React from 'react';
import './App.css';
import Souheikin from './Souheikin';

function App() {
  return (
    <div>
      <div>
        <span style={{ marginTop: 0, backgroundColor: '#616161', color: 'white' }}>SSS(総平均法 損益 操作)</span>
      </div>
      <Souheikin />
    </div>
  );
}

export default App;
