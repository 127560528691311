import AvenueIF from "./AvenueIF";
import { convertArg } from "./AvenueUtil";
import Finance from "../Finance";
import Recipe from "./Recipe";
import BigNumber from "bignumber.js";

export default class KainomiAvenue implements AvenueIF {
    name = `購入のみ`;

    public getMoukeLimit(finance: Finance) {
        const { kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka } = finance;

        const { j, k, n } = convertArg(BigNumber(-1), kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka);
        // const m = j * (k - n);
        const m = j.multipliedBy(k.minus(n));
        const minusPattenRyou = this.getRecipe(m.minus(1), finance);
        //if (minusPattenRyou !== null && 0 < minusPattenRyou.kaiRyou) {
        if (minusPattenRyou !== null && BigNumber(0).isLessThan(minusPattenRyou.kaiRyou)) {

            return m.minus(1);
        }
        const plusPattenRyou = this.getRecipe(m.plus(1), finance);
        //if (plusPattenRyou !== null && 0 < plusPattenRyou.kaiRyou) {
        if (plusPattenRyou !== null && BigNumber(0).isLessThan(plusPattenRyou.kaiRyou)) {
            return m.plus(1);
        }
        return null;
    }

    getRecipe(mouke: BigNumber, finance: Finance) {
        const { kaiRyou, kaiYen, kaiTanka, uriRyou, uriYen, uriTanka, nowTanka } = finance;

        const { m, a, b, j, k, n } = convertArg(mouke, kaiRyou, kaiTanka, uriRyou, uriTanka, nowTanka);

        //if (j * (k - n) - m === 0) return null;
        if (j.multipliedBy(k.minus(n)).minus(m).isEqualTo(0)) return null;

        //const u = (a * (b * j - j * k + m)) / (j * (k - n) - m);
        const u = a.multipliedBy(b.multipliedBy(j).minus(j.multipliedBy(k)).plus(m)).dividedBy(j.multipliedBy(k.minus(n)).minus(m));
        const z = BigNumber(0);
        const addKaiRyou = u;
        const addUriRyou = z;
        //if (addKaiRyou <= 0 || addUriRyou === Infinity) {
        if (addKaiRyou.isLessThanOrEqualTo(0) || addUriRyou.isEqualTo(Infinity)) {
            return null;
        }

        const afterFin = Finance.create({ kaiRyou, kaiYen, uriRyou, uriYen, nowTanka, addKaiRyou, addUriRyou });
        return new Recipe(this.name, addKaiRyou, addUriRyou, afterFin);
    }
}
